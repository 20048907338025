import React from 'react';
import { Col, Form, Input, Row } from 'antd';
import { API } from 'htcore';
import apiMethods from 'core/methods';

const ExistedMappingForm = ({ form }) => {
    const clearPreviewFields = () => {
        form.setFieldValue('hotelName', null);
        form.setFieldValue('address', null);
    };

    const onChangeAccommodationId = (event) => {
        const { value } = event.target;

        if (!value) {
            clearPreviewFields();
            return;
        }

        API.get({
            shimabara: apiMethods.accommodationById(value),
            ignoreErrors: true,
            success: (result) => {
                form.setFieldValue('hotelName', result.name);
                form.setFieldValue('address', result.address);
            },
            error: () => clearPreviewFields(),
        });
    };

    return (
        <>
            <Row gutter={20}>
                <Col span={6}>
                    <Form.Item
                        name="htAccommodationId"
                        label="Supplier Accommodation ID"
                        rules={[{ required: true, message: 'Please Enter Accommodation ID' }]}
                    >
                        <Input
                            maxLength={128}
                            placeholder="Supplier Accommodation ID"
                            onChange={onChangeAccommodationId}
                        />
                    </Form.Item>
                </Col>

                <Col span={6}>
                    <Form.Item name="hotelName" label="Hotel Name">
                        <Input disabled placeholder="Hotel Name" />
                    </Form.Item>
                </Col>

                <Col span={6}>
                    <Form.Item name="address" label="Address">
                        <Input disabled placeholder="Address" />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={20}>
                <Col span={6}>
                    <Form.Item
                        name="supplierPropertyId"
                        label="Happy Travel Accommodation ID"
                        rules={[{ required: true, message: 'Please Enter Accommodation ID' }]}
                    >
                        <Input maxLength={128} placeholder="Happy Travel Accommodation ID" />
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
};

export default ExistedMappingForm;
