import React from 'react';
import { date } from 'legacy';
import apiMethods from 'core/methods';
import { Form, Typography, Row, Col, Input, InputNumber, DatePicker, Checkbox, Space, Button } from 'antd';
import Table from 'components/table';
import CurrencySelector from 'components/selectors/currency-selector';
import MealPlanSelector from 'components/selectors/meal-plan-selector';
import TitleSelector from 'components/selectors/title-selector';
import { passengersValidation } from './passenger-details';
import { getUTCDate } from 'components/selectors/date-ranges-selector';
import { Copy } from 'iconsax-react';
import { toFixedDownString } from './use-currencies';

const { Title } = Typography;

const requiredRule = { required: true, message: 'Please enter value' };

const pricingColumns = (onChangePrice, copyRoomValue) => [
    {
        header: 'Room Type',
        cell: ({ index }) => (
            <Form.Item name={['rooms', index, 'contractName']} rules={[{ max: 50, message: 'Too long' }]}>
                <Input placeholder="Room Type" />
            </Form.Item>
        ),
    },
    {
        header: 'Meal Plan',
        cell: ({ index }) => (
            <Form.Item noStyle shouldUpdate>
                {({ setFieldValue }) => (
                    <Form.Item name={['rooms', index, 'mealPlan']}>
                        <MealPlanSelector
                            placeholder="Meal Plan"
                            onChange={(_, item) => setFieldValue(['rooms', index, 'mealPlan'], item)}
                        />
                    </Form.Item>
                )}
            </Form.Item>
        ),
    },
    {
        header: 'Adults',
        cell: ({ index }) => (
            <Form.Item name={['rooms', index, 'adultsNumber']}>
                <InputNumber placeholder="0" min={1} max={10} />
            </Form.Item>
        ),
    },
    {
        header: 'Children',
        cell: ({ index }) => (
            <Form.Item name={['rooms', index, 'childrenNumber']}>
                <InputNumber placeholder="0" min={0} max={5} />
            </Form.Item>
        ),
    },
    {
        header: 'Deadline',
        cell: ({ index }) => (
            <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                    prevValues.isNonRefundable !== currentValues.isNonRefundable
                }
            >
                {({ getFieldValue }) =>
                    getFieldValue('isNonRefundable') ? (
                        'Non-refundable'
                    ) : (
                        <Form.Item name={['rooms', index, 'deadlineDate']}>
                            <DatePicker
                                disabledDate={(current) => current.isBefore(getUTCDate())}
                                format={date.format.c}
                                placeholder="Deadline"
                            />
                        </Form.Item>
                    )
                }
            </Form.Item>
        ),
    },
    {
        header: (
            <span>
                Buying Price <span style={{ color: '#ff4d4f' }}> *</span>
            </span>
        ),
        cell: ({ index }) => (
            <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                    prevValues.supplierCurrency !== currentValues.supplierCurrency
                }
            >
                {({ getFieldValue, setFieldValue }) => (
                    <Form.Item name={['rooms', index, 'amount']} rules={[requiredRule]}>
                        <InputNumber
                            min={0}
                            placeholder="0"
                            addonBefore={getFieldValue('supplierCurrency') || ''}
                            onChange={() =>
                                onChangePrice(setFieldValue, getFieldValue, ['rooms', index, 'amount'])
                            }
                        />
                    </Form.Item>
                )}
            </Form.Item>
        ),
    },
    {
        header: (
            <span>
                Lead Passenger<span style={{ color: '#ff4d4f' }}> *</span>
            </span>
        ),
        cell: ({ index, isWithCopy }) => (
            <Space>
                <Form.Item name={['rooms', index, 'leadPassenger', 'title']} rules={[requiredRule]}>
                    <TitleSelector />
                </Form.Item>
                <Form.Item
                    name={['rooms', index, 'leadPassenger', 'firstName']}
                    rules={[requiredRule, ...passengersValidation]}
                >
                    <Input placeholder="First Name" />
                </Form.Item>
                <Form.Item
                    name={['rooms', index, 'leadPassenger', 'lastName']}
                    rules={[requiredRule, ...passengersValidation]}
                >
                    <Input placeholder="Last Name" />
                </Form.Item>

                <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue, setFieldValue }) =>
                        !index &&
                        isWithCopy && (
                            <Button
                                onClick={() => copyRoomValue(getFieldValue, setFieldValue)}
                                icon={<Copy size="28" color="#FF8A65" />}
                            />
                        )
                    }
                </Form.Item>
            </Space>
        ),
    },
];

const RoomPricing = ({ creation }) => {
    const createTableList = (roomsCount) => {
        return new Array(roomsCount).fill(1).map((_, index) => ({ index, isWithCopy: roomsCount > 1 }));
    };

    const onChangePrice = (setFieldValue, getFieldValue, field) => {
        const value = getFieldValue(field);

        setFieldValue(field, value ? toFixedDownString(value) : value);
    };

    const copyRoomValue = (getFieldValue, setFieldValue) => {
        const emptyRoomsArray = createTableList(getFieldValue('roomsCount'));
        const rooms = getFieldValue('rooms');

        const newRooms = emptyRoomsArray.map(() => rooms?.[0] || {});

        setFieldValue('rooms', newRooms);
    };

    return (
        <div>
            <Title level={4}>Room Pricing</Title>

            <Row size="large" style={{ alignItems: 'center', marginBottom: 20 }}>
                <Col span={12} style={{ paddingRight: 30 }}>
                    <Form.Item
                        name="supplierCurrency"
                        label="Supplier Currency"
                        rules={[{ required: true, message: 'Please select Currency' }]}
                    >
                        <CurrencySelector url={apiMethods.currencies} disabled={!creation} />
                    </Form.Item>
                </Col>

                <Form.Item name="isNonRefundable" valuePropName="checked">
                    <Checkbox defaultChecked={false}>Non-Refundable</Checkbox>
                </Form.Item>
            </Row>

            <Row size="large" style={{ marginBottom: 40 }} className="inputs-table">
                <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => (
                        <>
                            <Table
                                style={{ width: '100%' }}
                                list={createTableList(getFieldValue('roomsCount'))}
                                columns={pricingColumns(onChangePrice, copyRoomValue)}
                            />
                        </>
                    )}
                </Form.Item>
            </Row>
        </div>
    );
};

export default RoomPricing;
