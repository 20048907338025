import React from 'react';
import apiMapperMethods from 'core/mapper-methods';
import { RequestSelector } from 'common/request-selector';

const optionsGenerator = (list = []) =>
    list.map(({ countryName, code, id }) => ({ label: countryName, value: code, id }));

const AccommodationCountrySelector = (props) => (
    <RequestSelector
        request={(value) => ({
            shimabara: apiMapperMethods.accommodationCountry,
            body: { query: value },
        })}
        optionsGenerator={optionsGenerator}
        placeholder="Any Country"
        {...props}
    />
);

export default AccommodationCountrySelector;
