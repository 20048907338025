const apiMethods = {
    agencies: `/admin/agencies`,
    verifyFullAccess: (agencyId) => `/admin/agencies/${agencyId}/verify-full-access`,
    verifyReadonly: (agencyId) => `/admin/agencies/${agencyId}/verify-read-only`,
    declineVerification: (agencyId) => `/admin/agencies/${agencyId}/decline-verification`,
    changeContractKind: (agencyId) => `/admin/agencies/${agencyId}/contract-kind`,
    agency: (agencyId) => `/admin/agencies/${agencyId}`,
    agencyCreate: () => `/admin/agencies/without-registration`,
    agenciesAccounts: (agencyId) => `/admin/agencies/${agencyId}/accounts`,
    activateAgency: (agencyId) => `/admin/agencies/${agencyId}/activate`,
    deactivateAgency: (agencyId) => `/admin/agencies/${agencyId}/deactivate`,
    agencyMarkups: (agencyId) => `/admin/agencies/${agencyId}/markups`,
    agencyAgents: (agencyId) => `/admin/agencies/${agencyId}/agents`,
    agencyAgent: (agencyId, agentId) => `/admin/agencies/${agencyId}/agents/${agentId}/details`,
    agencyInviteAgentCreate: (agencyId) => `/admin/agencies/${agencyId}/create-invitation`,
    agencyInviteAgentSend: (agencyId) => `/admin/agencies/${agencyId}/send-invitation`,
    agencySupplierSettings: (agencyId) => `/admin/agencies/${agencyId}/suppliers`,
    agencySystemSettings: (agencyId) => `/admin/agencies/${agencyId}/system-settings/availability-search`,
    agencyHistory: (agencyId) => `/admin/agencies/${agencyId}/logs`,
    agentHistory: (agentId) => `/admin/agents/${agentId}/logs`,
    agencyAvailableCurrencies: (agencyId) =>
        `/admin/agencies/${agencyId}/system-settings/available-currencies`,
    agentSystemSettings: (agencyId, agentId) =>
        `/admin/agencies/${agencyId}/agents/${agentId}/system-settings/availability-search`,
    agencyRequests: '/admin/agencies/requests',
    agencyRequest: (requestId) => `/admin/agencies/requests/${requestId}`,
    agencyRequestsAwaiting: `/admin/agencies/requests/awaiting`,
    agencyRequestReject: (requestId) => `/admin/agencies/requests/${requestId}/reject`,
    agencyRequestRecover: (requestId) => `/admin/agencies/requests/${requestId}/recover`,
    agencyRequestHistory: (requestId) => `/admin/agencies/requests/${requestId}/history/last-rejected`,
    requestAccountManager: (requestId) => `/admin/agencies/requests/${requestId}/account-managers`,
    inviteMasterAgent: (agencyId) => `/admin/agencies/${agencyId}/master-agent/request/send`,
    inviteMasterAgentResend: (agencyId, invitationCodeHash) =>
        `/admin/agencies/${agencyId}/master-agent/request/${invitationCodeHash}/resend`,
    inviteMasterAgentDetails: (agencyId, invitationCodeHash) =>
        `/admin/agencies/${agencyId}/master-agent/request/${invitationCodeHash}`,
    contractFiles: (agencyId) => `/admin/agencies/${agencyId}/contract-file`,
    contractFile: (agencyId, documentId) => `/admin/agencies/${agencyId}/contract-file/${documentId}`,
    createPaymentLink: '/external/payment-links',
    createPaymentLinkAndSend: '/external/payment-links/send',
    adminSendInvitation: `/admin/invitations`,
    adminRegister: `/admin/invitations/accept`,
    adminCurrentAuth: `/admin/administrators/current`,
    adminCurrentNotificationSettings: `/admin/notifications/settings`,
    administrators: `/admin/administrators`,
    adminsOffline: `/admin/accommodations/offline-bookings/creators`,
    administratorRoles: `/admin/administrator-roles`,
    administratorRole: (roleId) => `/admin/administrator-roles/${roleId}`,
    allAgentRolesList: `/admin/agent-roles`,

    rolesOfAdministrator: (administratorId) => `/admin/administrators/${administratorId}/roles`,
    administratorActivate: (administratorId) => `/admin/administrators/${administratorId}/activate`,
    administratorDeactivate: (administratorId) => `/admin/administrators/${administratorId}/deactivate`,

    agentChangeAgency: (agencyId, agentId) => `/admin/agencies/${agencyId}/agents/${agentId}/change-agency`,
    agentChangeEmail: (agencyId, agentId) => `/admin/agencies/${agencyId}/agents/${agentId}/change-email`,
    agentSuppliers: (agencyId, agentId) => `/admin/agencies/${agencyId}/agents/${agentId}/suppliers`,
    agentCredentials: (agencyId, agentId) => `/admin/agencies/${agencyId}/agents/${agentId}/api-credentials`,
    agentChangeCredentials: (agencyId, agentId, type) =>
        `/admin/agencies/${agencyId}/agents/${agentId}/api-credentials/${type}`,
    agentApiClient: (agencyId, agentId, query = '') =>
        `/admin/agencies/${agencyId}/agents/${agentId}/system-settings/availability-search/api-client${query}`,
    bookingsByReferenceCode: (referenceCode, isService) =>
        `/admin/${isService ? 'service-bookings' : 'bookings'}/${referenceCode}`,
    serviceByReferenceCode: (referenceCode) => `/admin/accommodations/service-bookings/${referenceCode}`,
    offlineBookingsByReferenceCode: (referenceCode) =>
        `/admin/accommodations/offline-bookings/${referenceCode}`,
    bookingByInvoiceNumber: (invoiceNumber) =>
        `/admin/accommodations/bookings/find-by-invoice?number=${invoiceNumber}`,
    bookingsList: `/admin/accommodations/bookings`,
    bookingsListReportDownload: `/admin/accommodations/bookings/report`,
    autoCancellationStatuses: () => `/admin/accommodations/bookings/set-auto-cancellation`,
    bookingVoucher: (bookingId) => `/admin/accommodations/bookings/${bookingId}/voucher-pdf`,
    bookingAllVouchers: (bookingId) => `/admin/accommodations/bookings/${bookingId}/vouchers-archive`,
    bookingInvoice: (bookingId, isService) =>
        `/admin/accommodations/${isService ? 'service-bookings' : 'bookings'}/${bookingId}/invoice-pdf`,
    bookingAllInvoices: (bookingId, isService) =>
        `/admin/accommodations/${isService ? 'service-bookings' : 'bookings'}/${bookingId}/invoices-archive`,
    bookingDiscard: (bookingId) => `/admin/accommodations/bookings/${bookingId}/discard`,
    bookingCancel: (bookingId) => `/admin/accommodations/bookings/${bookingId}/cancel`,
    bookingCancelManually: (bookingId) => `/admin/accommodations/bookings/${bookingId}/cancel-manually`,
    bookingReject: (bookingId) => `/admin/accommodations/bookings/${bookingId}/reject-manually`,
    bookingRefreshStatus: (bookingId) => `/admin/accommodations/bookings/${bookingId}/refresh-status`,
    bookingStatusHistory: (bookingId, isService) =>
        `/admin/accommodations/${isService ? 'service-bookings' : 'bookings'}/${bookingId}/status-history`,
    bookingCancellationPenalty: (bookingId) =>
        `/admin/accommodations/bookings/${bookingId}/cancellation-penalty`,
    bookingHotelConfirmationCode: (referenceCode, isService) =>
        `/admin/accommodations/${
            isService ? 'service-bookings' : 'bookings'
        }/${referenceCode}/hotel-confirmation-code`,
    offlineBookings: '/admin/accommodations/offline-bookings',
    offlineBooking: (bookingId) => `/admin/accommodations/offline-bookings/${bookingId}`,
    confirmBooking: (referenceCode) =>
        `/admin/accommodations/offline-bookings/${referenceCode}/confirm-manually`,
    cancelOfflineBooking: (referenceCode) => `/admin/accommodations/offline-bookings/${referenceCode}/cancel`,
    bookingRemarks: (referenceCode) => `/admin/accommodations/bookings/${referenceCode}/remarks`,

    mealService: (referenceCode) => `/admin/accommodations/meal-services/${referenceCode}`,
    createMealService: '/admin/accommodations/meal-services',
    confirmMealService: (referenceCode) =>
        `/admin/accommodations/meal-services/${referenceCode}/confirm-manually`,
    cancelMealService: (referenceCode) => `/admin/accommodations/meal-services/${referenceCode}/cancel`,
    servicesList: '/admin/accommodations/service-bookings',

    manuallyCompletePayment: (bookingId) => `/admin/payments/offline/accommodations/bookings/${bookingId}`,
    manuallyCompletePaymentOfflineService: (bookingId) =>
        `/admin/payments/offline-booking-services/${bookingId}`,
    confirmManually: (bookingId) => `/admin/accommodations/bookings/${bookingId}/confirm-manually`,
    agencyAccountHistory: (agencyId, accountId) =>
        `/admin/agencies/${agencyId}/accounts/${accountId}/history`,
    agencyAccountHistoryDownload: (agencyId, accountId, fileType) =>
        `/admin/agencies/${agencyId}/accounts/${accountId}/history/${fileType}`,
    accountOperation: (agencyAccountId, operation) =>
        `/admin/agency-accounts/${agencyAccountId}/${operation}`,
    agencyAccountManagers: () => `/admin/account-managers`,
    agencyUpdateAccountManagers: (agencyId) => `/admin/agencies/${agencyId}/account-manager`,

    markets: () => `/admin/locations/markets`,
    market: (id) => `/admin/locations/markets/${id}`,
    marketCountries: (id) => `/admin/locations/markets/${id}/countries`,
    allCountries: () => '/admin/locations/countries',

    markups: () => `/admin/markups`,
    markup: (id) => `/admin/markups/${id}`,
    markupActivate: (id) => `/admin/markups/${id}/activate`,
    markupDeactivate: (id) => `/admin/markups/${id}/deactivate`,
    markupHistory: (id) => `/admin/markups/${id}/history`,
    markupOptions: '/admin/markups/available-options',

    companyBanks: () => `/admin/company/banks`,
    companyBank: (bankId) => `/admin/company/banks/${bankId}`,
    companyBankAccounts: (bankId) => `/admin/company/banks/${bankId}/accounts`,
    companyBankAccount: (bankId, accountId) => `/admin/company/banks/${bankId}/accounts/${accountId}`,
    companyBankAccountSetDefault: (bankId, accountId) =>
        `/admin/company/banks/${bankId}/accounts/${accountId}/set-default`,
    companyCurrencies: () => `/admin/company/available-currencies`,

    currencies: '/admin/currencies',
    boardBasisTypes: '/admin/board-basis-types',

    accommodationsSearch: `/admin/mapper/management/accommodations/search`,
    unmappedAccommodationSearch: (value) => `/admin/unmapped-accommodations/search?searchTerm=${value}`,
    unmappedAccommodationSearchById: (id) => `/admin/unmapped-accommodations/${id}`,
    unmappedAccommodation: '/admin/unmapped-accommodations',

    newslettersMaintenance: () => '/admin/newsletters/maintenance',
    newslettersRelease: () => '/admin/newsletters/release',

    paymentProcessors: () => '/payments/payment-processors',

    defaultBuffer: '/admin/currencyManagement/currencyBuffer',
    updateDefaultBuffer: (defaultBuffer) => `/admin/currencyManagement/updateBuffer/${defaultBuffer}`,
    defaultBufferHistory: '/admin/currencyManagement/logs',
    allRates: '/admin/currencyManagement/allRates',
    rateCreate: '/admin/currencyManagement/create',
    rateUpdate: '/admin/currencyManagement/update',
    rateDelete: (rateId) => `/admin/currencyManagement/${rateId}`,
    vccRate: (provider, cardType, currency, accountNumber) =>
        `/admin/currencyManagement/vccRates/${provider}/${cardType}/${currency}/${accountNumber}`,
    vccCreateRate: `/currencyExchangeRate`,
    vccUpdateRate: `/currencyExchangeRate/update`,
    deleteVccRate: (rateId, userId) => `/currencyExchangeRate/${userId}/${rateId}`,

    marketRestrictions: (marketId, keepRestrictions) =>
        `/admin/restrictions/market/${marketId}?keepExistingSubRestrictions=${!!keepRestrictions}`,
    countriesRestrictions: (countryId, keepRestrictions) =>
        `/admin/restrictions/country/${countryId}?keepExistingSubRestrictions=${!!keepRestrictions}`,
    restrictionsHistory: (skip, top) => `/admin/restrictions/history?skip=${skip}&top=${top}`,

    itineraryCodes: (agencyId) => `/admin/itinerary/get-suitable?agencyId=${agencyId}`,
    customerCurrencyByItn: (itineraryNumber) =>
        `/admin/itinerary/get-customer-currency?itineraryNumber=${itineraryNumber}`,

    hotels: (agencyId) => `/admin/agencies/${agencyId}/agents-accommodation-counts`,
    hotelsList: (agencyId, agentId) => `/admin/agencies/${agencyId}/agents/${agentId}/report`,
    hotelsCount: (agencyId, agentId) => `/admin/agencies/${agencyId}/agents/${agentId}/accommodations-count`,
    hotelsTemplate: (agencyId, agentId) => `/admin/agencies/${agencyId}/agents/${agentId}/accommodation-list`,

    suppliersByHtId: (id) => `/admin/locations/${id}/suppliers`,

    supplierRestrictions: '/admin/supplier-restrictions',
    supplierOptions: '/admin/supplier-restrictions/available-options',
    restriction: (id) => `/admin/supplier-restrictions/${id}`,
    updateRestriction: (id) => `/admin/supplier-restrictions/${id}`,
    activateRestriction: (id) => `/admin/supplier-restrictions/${id}/activate`,
    deactivateRestriction: (id) => `/admin/supplier-restrictions/${id}/deactivate`,
    supplierRestrictionsHistory: '/admin/supplier-restrictions/history',
    openedAgenciesWeb: (supplierCode) => `/admin/suppliers/${supplierCode}/opened-agencies/web`,
    openedAgenciesApi: (supplierCode) => `/admin/suppliers/${supplierCode}/opened-agencies/api`,
    agentSupplierSettingsActivate: (supplierCode, agentId, agencyId) =>
        `/admin/suppliers/${supplierCode}/agencies/${agencyId}/agents/${agentId}/activate`,
    agentSupplierSettingsDeactivate: (supplierCode, agentId, agencyId) =>
        `/admin/suppliers/${supplierCode}/agencies/${agencyId}/agents/${agentId}/deactivate`,

    roomMappingSettings: '/admin/global-settings/room-grouping',
    roomMappingHistory: '/admin/global-settings/room-grouping/history',
    accommodationById: (htId) => `/admin/accommodations/${htId}`,
    accommodationByGiataId: (giataId) => `/admin/accommodations/giata/${giataId}`,
};

export default apiMethods;
