import React from 'react';
import { Button, Col, Form, Radio, Row, Typography } from 'antd';
import MapperSuppliersSelector from '../run-mapping/mapping-suppliers-selector';
import ExistedMappingForm from './existed-mapping-form';
import NewMappingForm from './new-mapping-form';
import { API } from 'htcore';
import apiShimabaraMethods from 'core/shimabara-methods';
import { notifications } from 'components/notification';

const { Title } = Typography;

const MappingValues = {
    New: 'new',
    Existed: 'existed',
};

const fieldsForReset = [
    'supplierCode',
    'hotelName',
    'address',
    'htAccommodationId',
    'giataId',
    'supplierPropertyId',
    'countryCode',
    'localityHtId',
];

const ManualMapping = () => {
    const [form] = Form.useForm();

    const onSubmit = ({ mapping, ...values }) => {
        const { htAccommodationId, supplierCode, supplierPropertyId, localityHtId, giataId } = values;

        if (mapping === MappingValues.Existed) {
            API.post({
                shimabara: apiShimabaraMethods.mapSupplierProperties,
                body: { htAccommodationId, supplierCode, supplierPropertyId },
                success: () => {
                    notifications('Mapped!', 'success');
                    form.resetFields(fieldsForReset);
                },
            });
            return;
        }

        API.post({
            shimabara: apiShimabaraMethods.mapSupplierPropertiesToNew,
            body: { supplierCode, supplierPropertyId, localityHtId, giataId },
            success: () => {
                notifications('Mapped!', 'success');
                form.resetFields(fieldsForReset);
            },
        });
    };

    return (
        <>
            <Title level={5}>Map to</Title>

            <Form form={form} initialValues={{ mapping: 'existed' }} layout="vertical" onFinish={onSubmit}>
                <Row size="large" gutter={20}>
                    <Col span={6}>
                        <Form.Item name="mapping">
                            <Radio.Group onChange={() => form.resetFields(fieldsForReset)}>
                                <Radio value={MappingValues.Existed}>Existed</Radio>
                                <Radio value={MappingValues.New}>New</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            name="supplierCode"
                            label="Supplier"
                            rules={[{ required: true, message: 'Please select Supplier' }]}
                        >
                            <MapperSuppliersSelector />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) =>
                        getFieldValue('mapping') === MappingValues.New ? (
                            <NewMappingForm form={form} />
                        ) : (
                            <ExistedMappingForm form={form} />
                        )
                    }
                </Form.Item>

                <Row size="large" gutter={20}>
                    <Col span={6}>
                        <Button htmlType="submit" type="primary" style={{ width: '100%' }}>
                            Map
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default ManualMapping;
