import React, { useEffect, useState } from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { Menu, PageHeader } from 'antd';
import { API } from 'htcore';
import apiMappingMethods from 'core/mapping-methods';
import RunMapping from './run-mapping/run-mapping';
import JobsQueue from './jobs-queue/jobs-queue';
import CrossCodes from './cross-codes/cross-codes';
import ManualMapping from './manual-mapping/manual-mapping';

const getItems = (count) => [
    { label: <Link to="./">Run Mapping</Link>, key: '' },
    { label: <Link to="./jobs-queue">Jobs Queue ({count})</Link>, key: 'jobs-queue' },
    { label: <Link to="./cross-codes">Upload Cross-Codes</Link>, key: 'cross-codes' },
    { label: <Link to="./manual-mapping">Manual mapping</Link>, key: 'manual-mapping' },
];

const MappingPage = () => {
    const [count, setCount] = useState(0);

    const location = useLocation();

    useEffect(() => {
        API.get({
            mapper: apiMappingMethods.jobsCount,
            success: setCount,
        });
    }, []);

    return (
        <>
            <PageHeader title="Mapping" />
            <Menu
                mode="horizontal"
                items={getItems(count)}
                selectedKeys={location.pathname.split('/')[2] || ''}
            />
            <Routes>
                <Route path="/" element={<RunMapping />} />
                <Route path="/jobs-queue" element={<JobsQueue />} />
                <Route path="/cross-codes" element={<CrossCodes />} />
                <Route path="/manual-mapping" element={<ManualMapping />} />
            </Routes>
        </>
    );
};

export default MappingPage;
