import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { copyToClipboard, Loader } from 'legacy';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import { Button, Form, Input, Modal, Space, Switch } from 'antd';
import { Danger } from 'iconsax-react';

const CREDENTIAL_TYPES = {
    DIRECT: 'DirectApiCredentials',
    COMMON: 'ApiCredentials',
};

const CREDENTIAL_NAMES = {
    [CREDENTIAL_TYPES.DIRECT]: 'Direct API or TGX API v2',
    [CREDENTIAL_TYPES.COMMON]: 'TGX API v1',
};

const AgentCredentialsSettings = () => {
    const [loading, setLoading] = useState(true);

    const [credentialsModalType, setCredentialsModalType] = useState(null);
    const [loadingModal, setLoadingModal] = useState(false);
    const [newCredentials, setNewCredentials] = useState(null);

    const [isPasswordHide, setIsPasswordHide] = useState(true);

    const [apiClient, setApiClient] = useState(null);

    const { id, agentId } = useParams();

    const [credentialsState, setCredentialsState] = useState({
        ApiCredentials: false,
        DirectApiCredentials: false,
    });

    const getData = () => {
        setLoading(true);
        API.get({
            url: apiMethods.agentCredentials(id, agentId),
            success: setCredentialsState,
            after: () => setLoading(false),
        });
    };

    const getApiClientData = () => {
        setLoading(true);
        API.get({
            url: apiMethods.agentApiClient(id, agentId),
            success: setApiClient,
            after: () => setLoading(false),
        });
    };

    const changeApiClient = () => {
        setLoading(true);
        API.put({
            url: apiMethods.agentApiClient(id, agentId, `?isApiClient=${!apiClient}`),
            success: () => setApiClient((prevState) => !prevState),
            after: () => setLoading(false),
        });
    };

    useEffect(() => {
        getData();
        getApiClientData();
    }, []);

    const onCloseModal = () => {
        setCredentialsModalType(null);
        setNewCredentials(null);
        getData();
    };

    const generateCredentials = () => {
        setLoadingModal(true);

        API.post({
            url: apiMethods.agentChangeCredentials(id, agentId, credentialsModalType),
            success: (result) => {
                setNewCredentials(result?.Value);
                setLoadingModal(false);
            },
        });
    };

    const disabledApiClientSwitch = useMemo(
        () => credentialsState.ApiCredentials || credentialsState.DirectApiCredentials,
        [credentialsState]
    );

    if (loading) {
        return <Loader />;
    }

    return (
        <>
            <Space direction="vertical">
                <Form.Item label="API Client" style={{ marginBottom: 0 }}>
                    <Switch
                        disabled={disabledApiClientSwitch}
                        checked={apiClient}
                        style={{ marginLeft: 10 }}
                        onChange={changeApiClient}
                    />
                </Form.Item>
                {disabledApiClientSwitch && (
                    <p style={{ marginBottom: 20, color: '#777777' }}>
                        API-credentials were processed for this agent, and the API Client setting cannot be
                        changed. Please create a new agent if web access is required.
                    </p>
                )}

                {apiClient && (
                    <>
                        <Button
                            onClick={() => setCredentialsModalType(CREDENTIAL_TYPES.DIRECT)}
                            style={{ marginBottom: 10 }}
                        >
                            {credentialsState?.DirectApiCredentials ? 'Reset' : 'Create'}
                            {' Credentials for  '}
                            {CREDENTIAL_NAMES[CREDENTIAL_TYPES.DIRECT]}
                        </Button>

                        <Button
                            onClick={() => setCredentialsModalType(CREDENTIAL_TYPES.COMMON)}
                            style={{ marginBottom: 10 }}
                        >
                            {credentialsState?.ApiCredentials ? 'Reset' : 'Create'}
                            {' Credentials for '}
                            {CREDENTIAL_NAMES[CREDENTIAL_TYPES.COMMON]}
                        </Button>
                    </>
                )}
            </Space>

            <Modal
                title="API Credentials"
                open={!!credentialsModalType}
                footer={
                    newCredentials ? (
                        <Button style={{ width: '100%' }} onClick={onCloseModal}>
                            Close
                        </Button>
                    ) : (
                        <Button type="primary" style={{ width: '100%' }} onClick={generateCredentials}>
                            Get {credentialsState?.[credentialsModalType] ? 'New' : ''} Credentials for{' '}
                            {CREDENTIAL_NAMES[credentialsModalType]}
                        </Button>
                    )
                }
                onCancel={onCloseModal}
            >
                {loadingModal ? (
                    <Loader />
                ) : !newCredentials ? (
                    <Space direction="vertical">
                        <p>
                            You can generate new credentials for {CREDENTIAL_NAMES[credentialsModalType]}{' '}
                            connection
                        </p>
                        <Space align="start" size="large">
                            <Danger size="32" color="#f9a51a" variant="Bold" />
                            <span>
                                {credentialsState?.[credentialsModalType] ? (
                                    <span>
                                        Once you click this button, the previous API credentials of the
                                        customer will be reset. Use it only if the customer doesn't know its
                                        current API login and API password, and you are sure that it will be
                                        able to quickly update the credentials in its systems. Don't forget to
                                        send them to the customer in a secure way: send the login directly to
                                        the person responsible for the connection on the customer's side and
                                        send the password to the same person but separately (via{' '}
                                        <a className="link" href="https://pwpush.com/" target="_blank">
                                            https://pwpush.com/
                                        </a>
                                        , for example).
                                    </span>
                                ) : (
                                    <span>
                                        Once you click this button, the API credentials will be created. Don't
                                        forget to send them to the customer in a secure way: send the login
                                        directly to the person responsible for the connection on the
                                        customer's side and send the password to the same person but
                                        separately (via{' '}
                                        <a className="link" href="https://pwpush.com/" target="_blank">
                                            https://pwpush.com/
                                        </a>
                                        , for example).
                                    </span>
                                )}
                            </span>
                        </Space>
                    </Space>
                ) : (
                    <Space direction="vertical">
                        <Space align="start" size="large" style={{ marginBottom: 10 }}>
                            <Danger size="32" color="#f9a51a" variant="Bold" />
                            <span>
                                New API Credentials Generated. Please copy them to your configuration files.
                                Also dont forget to replace any older credentials with provided.
                            </span>
                        </Space>

                        <Input
                            value={newCredentials?.name}
                            suffix={
                                <span
                                    style={{ cursor: 'pointer' }}
                                    className="link"
                                    onClick={() => copyToClipboard(newCredentials?.name)}
                                >
                                    Copy
                                </span>
                            }
                        />
                        <Input
                            value={newCredentials?.password}
                            type={isPasswordHide ? 'password' : 'text'}
                            suffix={
                                <Space size="large" style={{ cursor: 'pointer' }}>
                                    <span className="link" onClick={() => setIsPasswordHide(!isPasswordHide)}>
                                        {isPasswordHide ? 'Show' : 'Hide'}
                                    </span>
                                    <span
                                        className="link"
                                        onClick={() => copyToClipboard(newCredentials?.password)}
                                    >
                                        Copy
                                    </span>
                                </Space>
                            }
                        />
                    </Space>
                )}
            </Modal>
        </>
    );
};

export default AgentCredentialsSettings;
