import React, { useState } from 'react';
import { Button, message, Space } from 'antd';
import { API } from 'htcore';
import apiMappingMethods from 'core/mapping-methods';
import { Loader } from 'legacy';
import ConfirmationSimpleModal from 'components/confirms/confirmation-simple-modal';
import { notifications } from 'components/notification';

const SuppliersActions = ({ supplier, updateData }) => {
    const [openConfirm, setOpenConfirm] = useState(false);
    const [loading, setLoading] = useState(false);

    const download = (res, hideMessage) => {
        if (res.status !== 200) {
            hideMessage();
            notifications("Couldn't download", 'warning');
        } else {
            res.blob().then((blobby) => {
                let anchor = document.createElement('a');
                document.body.appendChild(anchor);

                const objectUrl = window.URL.createObjectURL(blobby);
                anchor.href = objectUrl;
                const contentDisposition = res.headers.get('content-disposition');
                const fileName = contentDisposition
                    ? contentDisposition.split('filename="')[1].split('";')[0]
                    : `cross-codes-for-${supplier}.csv`;

                anchor.download = fileName;
                anchor.click();

                window.URL.revokeObjectURL(objectUrl);
                hideMessage();
                message.success('Downloaded');
            });
        }
    };

    const getFile = () => {
        const hideMessage = message.loading(' Downloading...', 0);

        // todo request returns 500 error
        API.get({
            mapper: apiMappingMethods.crossCodesDownload(supplier),
            response: (result) => download(result, hideMessage),
        });
    };

    const onRemove = () => {
        setLoading(true);

        API.put({
            mapper: apiMappingMethods.crossCodesRemove(supplier),
            success: () => {
                notifications('File Removed!', 'success');
                updateData();
                setOpenConfirm(false);
            },
            after: () => setLoading(false),
        });
    };

    return (
        <>
            <Space>
                <Button type="link" onClick={getFile} style={{ padding: 0 }}>
                    <span style={{ color: '#1890FF', textDecoration: 'underline' }}>Download</span>
                </Button>

                <Button type="link" onClick={() => setOpenConfirm(true)} style={{ padding: 0 }}>
                    <span style={{ color: '#FF0000', textDecoration: 'underline' }}>Remove</span>
                </Button>
            </Space>

            <ConfirmationSimpleModal
                visible={openConfirm}
                onCancel={() => setOpenConfirm(false)}
                headerText="Confirmation"
                submitText="Remove"
                onConfirm={onRemove}
            >
                {loading ? <Loader /> : <span>Are you sure that you want to remove {supplier} file?</span>}
            </ConfirmationSimpleModal>
        </>
    );
};

export default SuppliersActions;
