import { useEffect, useState } from 'react';
import { API } from 'htcore';
import { date } from 'legacy';
import BigNumber from 'bignumber.js';
import { Form } from 'antd';

const RATES_DECIMAL_PLACES = 8;

export const toFixedDownString = (value, decimals = 2) => {
    const strValue = value.toString();
    const decimalIndex = strValue.indexOf('.');

    if (decimalIndex === -1 || strValue.length - decimalIndex - 1 <= decimals) {
        return strValue;
    }

    return strValue.substring(0, decimalIndex + decimals + 1);
};

const useCurrencies = ({ form, duplicate, booking }) => {
    const [supplierUsdRate, setSupplierUsdRate] = useState('');
    const [customerUsdRate, setCustomerUsdRate] = useState('');
    const [exchangeRate, setExchangeRate] = useState('');

    const supplierCurrency = Form.useWatch('supplierCurrency', form);
    const customerCurrency = Form.useWatch('customerCurrency', form);
    const fixedMarkupAmount = Form.useWatch('fixedMarkupAmount', form);

    const getDate = () => {
        if (duplicate || !booking?.creationDate) return '';
        return date.format.apiTime(booking?.creationDate);
    };

    const getUsdRate = () => {
        if (supplierCurrency) {
            API.get({
                tsutsujigasaki: `/Rates/${supplierCurrency}/USD`,
                body: { date: getDate() },
                success: (result) => setSupplierUsdRate(toFixedDownString(result, RATES_DECIMAL_PLACES)),
            });
        }
    };

    const getUsdCustomerRate = () => {
        if (customerCurrency) {
            API.get({
                tsutsujigasaki: `/Rates/${customerCurrency}/USD`,
                body: { date: getDate() },
                success: (result) => setCustomerUsdRate(toFixedDownString(result, RATES_DECIMAL_PLACES)),
            });
        }
    };

    const getExchangeRate = () => {
        if (customerCurrency && supplierCurrency) {
            API.get({
                tsutsujigasaki: `/Rates/${supplierCurrency}/${customerCurrency}`,
                body: { date: getDate() },
                success: (result) => setExchangeRate(toFixedDownString(result, RATES_DECIMAL_PLACES)),
            });
        }
    };

    useEffect(() => {
        getUsdRate();
        getUsdCustomerRate();
        getExchangeRate();
    }, [supplierCurrency, customerCurrency, booking]);

    const getCurrenciesData = (amount) => {
        const totalInUsd = new BigNumber(amount || 0).multipliedBy(supplierUsdRate || 0);
        const totalInCustomerCurrency = new BigNumber(amount || 0).multipliedBy(exchangeRate || 1);

        const totalWithMarkup = totalInCustomerCurrency.plus(fixedMarkupAmount);

        return {
            supplierCurrency: supplierCurrency || '',
            customerCurrency: customerCurrency || '',
            supplierUsdRate,
            customerUsdRate,
            totalInUsd: toFixedDownString(totalInUsd),
            exchangeRate,
            totalWithoutMarkup: toFixedDownString(totalInCustomerCurrency),
            totalInCustomerCurrency: toFixedDownString(totalWithMarkup),
        };
    };

    return { getCurrenciesData, exchangeRate };
};

export default useCurrencies;
