import React, { useState } from 'react';
import { Button, Form, Typography, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { API } from 'htcore';
import apiMappingMethods from 'core/mapping-methods';
import { Loader } from 'legacy';
import { notifications } from 'components/notification';
import MapperSuppliersSelector from '../run-mapping/mapping-suppliers-selector';

const { Title } = Typography;

const UploadFileForm = ({ updateData }) => {
    const [loading, setLoading] = useState(false);

    const [form] = Form.useForm();

    const onSubmit = ({ supplierId, files }) => {
        setLoading(true);
        const formDataBody = new FormData();

        formDataBody.append('file', files.file.originFileObj);

        API.put({
            mapper: apiMappingMethods.crossCodesUploadFile(supplierId),
            formDataBody,
            success: () => {
                notifications('File Uploaded!', 'success');
                form.resetFields();
                updateData();
            },
            after: () => setLoading(false),
        });
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <Form form={form} onFinish={onSubmit} layout="vertical" style={{ width: 350 }}>
            <Form.Item
                name="supplierId"
                label="Supplier"
                rules={[{ required: true, message: 'Please select Supplier' }]}
            >
                <MapperSuppliersSelector />
            </Form.Item>

            <Title level={5}>File</Title>

            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                    <Form.Item name="files">
                        <Upload
                            action={() => null}
                            listType="picture"
                            accept=".csv"
                            name="files"
                            multiple={false}
                            customRequest={({ onSuccess }) => onSuccess()}
                        >
                            <Button
                                style={{ width: 350 }}
                                icon={<UploadOutlined />}
                                disabled={getFieldValue('files')?.fileList?.length > 0}
                            >
                                Click to Upload
                            </Button>
                        </Upload>
                    </Form.Item>
                )}
            </Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                Upload
            </Button>
        </Form>
    );
};

export default UploadFileForm;
