const apiMapperMethods = {
    ratingTypes: `/admin/accommodations/rating-types`,
    accommodationDetailedData: (accommodationHtId) =>
        `/admin/mapper/management/accommodations/${accommodationHtId}/detailed-data`,
    accommodationChangeHistory: (htAccommodationId) =>
        `/admin/accommodations/${htAccommodationId}/change-history`,
    accommodationsReportCsv: `/admin/mapper/management/accommodations/report`,
    deactivationReasonTypes: `/admin/accommodations/deactivation-reason-types`,
    searchCountries: `/admin/locations/countries/search`,
    searchLocalitiesByCode: (countryCode) => `/admin/locations/country?code=${countryCode}`,
    searchLocalitiesByCodeAndQuery: '/admin/locations/localities/search',
    searchLocalities: (countryId) => `/admin/locations/countries/${countryId}/localities/search`,
    suppliersList: `/admin/mapper/management/suppliers`,

    preferredAvailableOptions: '/admin/preferred/available-options',
    accommodationPreferred: (accommodationHtId) => `/admin/preferred/${accommodationHtId}`,
    addAccommodationPreferred: '/admin/preferred',
    deactivatePreferredAccommodation: (accommodationHtId) =>
        `/admin/preferred/${accommodationHtId}/deactivate`,
    activatePreferredAccommodation: (accommodationHtId) => `/admin/preferred/${accommodationHtId}/activate`,
    historyPreferredSettings: (accommodationHtId) => `/admin/preferred/${accommodationHtId}/history`,

    accommodationVCCSettings: (accommodationHtId, supplierCode) =>
        `/admin/supplier-property-vcc-settings/suppliers/${supplierCode}/properties/${accommodationHtId}`,
    updateAccommodationVCCSettings: (supplierCode) =>
        `/admin/supplier-property-vcc-settings/suppliers/${supplierCode}`,

    accommodationRestriction: (accommodationHtId) => `/admin/restrictions/accommodation/${accommodationHtId}`,

    accommodationPaymentTypeSettings: '/admin/accommodations/payment-types',
    updateAccommodationPaymentTypeSettings: (accommodationHtId, supplierCode) =>
        `/admin/accommodations/${accommodationHtId}/suppliers/${supplierCode}/payment-type`,

    accommodationCountry: '/admin/locations/countries/search',
    accommodationLocality: (countryId) => `/admin/locations/countries/${countryId}/localities/search`,
};

export default apiMapperMethods;
