import React from 'react';
import apiMethods from 'core/methods';
import { date } from 'legacy';
import { Form, Typography, Row, Col, Input, InputNumber, Space } from 'antd';
import { CloseCircle } from 'iconsax-react';
import Table from 'components/table';
import CurrencySelector from 'components/selectors/currency-selector';
import TitleSelector from 'components/selectors/title-selector';
import { passengersValidation } from '../passenger-details';
import { toFixedDownString } from '../use-currencies';

const { Title } = Typography;

const requiredRule = { required: true, message: 'Please enter value' };

const pricingColumns = (onChangePrice) => [
    {
        header: 'Date',
        cell: () => (
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => {
                    const [dateFrom, dateTo] = getFieldValue('rangeDates') || [];
                    return `${date.format.dots(dateFrom)} - ${date.format.dots(dateTo)}`;
                }}
            </Form.Item>
        ),
    },
    {
        header: 'Service type',
        cell: () => (
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => getFieldValue('type')?.label || ''}
            </Form.Item>
        ),
    },
    {
        header: 'Adults',
        cell: ({ index }) => (
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                    <Form.Item
                        name={['rooms', index, 'adults']}
                        rules={[
                            {
                                validator(_, value) {
                                    const children = getFieldValue(['rooms', index, 'childrenNumber']);
                                    return !value && !children
                                        ? Promise.reject(
                                              new Error(
                                                  "The total guests in the room can't be 0, please change the number of adults/children"
                                              )
                                          )
                                        : Promise.resolve();
                                },
                            },
                        ]}
                        style={{ maxWidth: 215 }}
                    >
                        <InputNumber placeholder="0" min={0} max={10} />
                    </Form.Item>
                )}
            </Form.Item>
        ),
    },
    {
        header: 'Children',
        cell: ({ index }) => (
            <Form.Item noStyle shouldUpdate>
                {({ validateFields }) => (
                    <Form.Item name={['rooms', index, 'childrenNumber']}>
                        <InputNumber
                            placeholder="0"
                            min={0}
                            max={5}
                            onChange={() => validateFields([['rooms', index, 'adults']])}
                        />
                    </Form.Item>
                )}
            </Form.Item>
        ),
    },

    {
        header: (
            <span>
                Lead Passenger<span style={{ color: '#ff4d4f' }}> *</span>
            </span>
        ),
        cell: ({ index }) => (
            <Space>
                <Form.Item name={['rooms', index, 'title']} rules={[requiredRule]}>
                    <TitleSelector />
                </Form.Item>
                <Form.Item
                    name={['rooms', index, 'firstName']}
                    rules={[requiredRule, ...passengersValidation]}
                >
                    <Input placeholder="First Name" />
                </Form.Item>
                <Form.Item
                    name={['rooms', index, 'lastName']}
                    rules={[requiredRule, ...passengersValidation]}
                >
                    <Input placeholder="Last Name" />
                </Form.Item>
            </Space>
        ),
    },

    {
        header: (
            <span>
                Buying Price <span style={{ color: '#ff4d4f' }}> *</span>
            </span>
        ),
        cell: ({ index }) => (
            <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                    prevValues.supplierCurrency !== currentValues.supplierCurrency
                }
            >
                {({ getFieldValue, setFieldValue }) => (
                    <Form.Item name={['rooms', index, 'price']} rules={[requiredRule]}>
                        <InputNumber
                            min={0}
                            placeholder="0"
                            addonBefore={getFieldValue('supplierCurrency') || ''}
                            onChange={() =>
                                onChangePrice(setFieldValue, getFieldValue, ['rooms', index, 'price'])
                            }
                        />
                    </Form.Item>
                )}
            </Form.Item>
        ),
    },
];

const TotalBuyingPrice = ({ creation }) => {
    const onChangePrice = (setFieldValue, getFieldValue, field) => {
        const value = getFieldValue(field);

        setFieldValue(field, value ? toFixedDownString(value) : value);
    };

    const removeRoom = (code, rooms, setFieldValue) =>
        setFieldValue(
            'rooms',
            rooms.filter(({ roomCode }) => roomCode !== code)
        );

    return (
        <div>
            <Title level={4}>Total Buying Price</Title>

            <Row size="large" style={{ alignItems: 'center', marginBottom: 20 }}>
                <Col span={12} style={{ paddingRight: 30 }}>
                    <Form.Item
                        name="supplierCurrency"
                        label="Supplier Currency"
                        rules={[{ required: true, message: 'Please select Currency' }]}
                    >
                        <CurrencySelector url={apiMethods.currencies} disabled={!creation} />
                    </Form.Item>
                </Col>
            </Row>

            <Row size="large" className="inputs-table">
                <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue, setFieldValue }) => {
                        const rooms = getFieldValue('rooms');

                        return rooms?.map((item, index) => (
                            <div style={{ marginBottom: 40, width: '100%' }}>
                                <Space style={{ alignItems: 'center' }}>
                                    <Title level={5}>Service {index + 1}:</Title>

                                    <Form.Item name={['rooms', index, 'roomName']} rules={[requiredRule]}>
                                        <Input placeholder="Enter Service Name" style={{ marginBottom: 8 }} />
                                    </Form.Item>

                                    {rooms.length !== 1 && (
                                        <div
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => removeRoom(item.roomCode, rooms, setFieldValue)}
                                        >
                                            <CloseCircle size="20" color="#eb001b" variant="Bold" />
                                        </div>
                                    )}
                                </Space>

                                <Table
                                    rowKey="roomCode"
                                    style={{ width: '100%' }}
                                    list={[{ ...item, index }]}
                                    columns={pricingColumns(onChangePrice)}
                                />
                            </div>
                        ));
                    }}
                </Form.Item>
            </Row>
        </div>
    );
};

export default TotalBuyingPrice;
