import React from 'react';
import { Form, Button, Row, Col, Space, DatePicker, Select, Checkbox, Input, Collapse } from 'antd';
import { localStorage } from 'htcore';
import { BOOKING_STATUS, PAYMENT_STATUS, PAYMENT_TYPE } from 'htcore/enum';
import { date, remapStatus } from 'legacy';
import SupplierSelector from 'components/selectors/supplier-selector';
import AccommodationSelector from 'components/selectors/accommodation-selector';
import AgencySelector from 'components/selectors/agency-selector';
import AccountManagerSelector from 'components/selectors/account-manager-selector';
import MarketSelector from 'components/selectors/market-selector';
import AdminSelector from 'components/selectors/admin-selector';
import PaymentTypeSelector, { PAYMENT_TYPE_SUPPLIERS } from 'components/selectors/payment-type-selector';
import AgencyTypeSelector from 'components/selectors/agency-type-selector';
import AccommodationCountrySelector from 'components/selectors/accommodation-country-selector';
import AccommodationLocalitySelector from 'components/selectors/accommodation-locality-selector';

const { RangePicker } = DatePicker;

const SERVICE_STATUS = [
    BOOKING_STATUS.Created,
    BOOKING_STATUS.Confirmed,
    BOOKING_STATUS.Completed,
    BOOKING_STATUS.Cancelled,
];

const BookingSearchFormUnwrapped = ({
    bookingSearchForm,
    onSubmit,
    isOfflineBookings,
    isService,
    isAgencyOrAgent,
    children,
    style,
}) => (
    <Form form={bookingSearchForm} onFinish={onSubmit} layout="vertical" style={style}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12} xl={6}>
                <Form.Item name="rangeCreation" label="Creation">
                    <RangePicker
                        disabledDate={(date) => new Date() < date}
                        format={date.format.c}
                        placeholder={['From', 'Till']}
                    />
                </Form.Item>
            </Col>
            <Col span={12} xl={6}>
                <Form.Item name="rangeDeadline" label="Deadline">
                    <RangePicker format={date.format.c} placeholder={['From', 'Till']} />
                </Form.Item>
            </Col>
            <Col span={24} xl={12}>
                <Form.Item name="htId" label="Accommodation">
                    <AccommodationSelector filter={['Accommodation']} />
                </Form.Item>
            </Col>
            <Col span={12} xl={6}>
                <Form.Item name="rangeCheckIn" label="Check-In">
                    <RangePicker format={date.format.c} placeholder={['From', 'Till']} />
                </Form.Item>
            </Col>
            <Col span={12} xl={6}>
                <Form.Item name="rangeCheckOut" label="Check-Out">
                    <RangePicker format={date.format.c} placeholder={['From', 'Till']} />
                </Form.Item>
            </Col>

            {!isAgencyOrAgent && (
                <>
                    <Form.Item shouldUpdate noStyle>
                        {({ setFieldValue }) => (
                            <Col span={12} xl={6}>
                                <Form.Item name="agencyType" label="Agency Type">
                                    <AgencyTypeSelector
                                        onChange={(value) => {
                                            value !== 'all' && setFieldValue('agencies', []);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        )}
                    </Form.Item>
                    <Form.Item shouldUpdate noStyle>
                        {({ getFieldValue, setFieldValue }) => {
                            const agencyType = getFieldValue('agencyType');
                            return (
                                <Col span={12} xl={6}>
                                    <Form.Item name="agencies" label="Agencies">
                                        <AgencySelector
                                            multiple
                                            onlyActiveAgencies={agencyType === 'active'}
                                            onlySurrogateAgencies={agencyType === 'surrogate'}
                                            onChange={(_, data) => {
                                                setFieldValue('agencies', data);
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            );
                        }}
                    </Form.Item>
                </>
            )}

            <Col span={12} xl={6}>
                <Form.Item name="mainPassengerName" label="Main Guest">
                    <Input placeholder="Main Guest Name" />
                </Form.Item>
            </Col>
            <Col span={12} xl={6}>
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues.isActionRequired !== currentValues.isActionRequired
                    }
                >
                    {({ getFieldValue }) => (
                        <Form.Item name="statuses" label="Statuses">
                            <Select
                                mode="multiple"
                                placeholder={
                                    getFieldValue('isActionRequired') ? 'Action Required' : 'Any Status'
                                }
                                notFoundContent="Empty"
                                disabled={getFieldValue('isActionRequired')}
                                showArrow
                                allowClear
                                maxTagCount={2}
                                options={(isService ? SERVICE_STATUS : Object.keys(BOOKING_STATUS)).map(
                                    (status) => ({
                                        value: status,
                                        label: remapStatus(status),
                                    })
                                )}
                            />
                        </Form.Item>
                    )}
                </Form.Item>
            </Col>
            <Col span={12} xl={6}>
                <Form.Item name="paymentStatuses" label="Payment Statuses">
                    <Select
                        mode="multiple"
                        placeholder="Any Payment Status"
                        notFoundContent="Empty"
                        showArrow
                        allowClear
                        maxTagCount={2}
                        options={Object.keys(PAYMENT_STATUS).map((status) => ({
                            value: status,
                            label: remapStatus(status),
                        }))}
                    />
                </Form.Item>
            </Col>
            <Col span={12} xl={6}>
                <Form.Item name="paymentTypes" label="Payment Method">
                    <Select
                        mode="multiple"
                        placeholder="Any Payment Method"
                        notFoundContent="Empty"
                        showArrow
                        allowClear
                        maxTagCount={2}
                        options={Object.keys(PAYMENT_TYPE).map((status) => ({
                            value: status,
                            label: remapStatus(status),
                        }))}
                    />
                </Form.Item>
            </Col>
            <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                    JSON.stringify(prevValues.statuses) !== JSON.stringify(currentValues.statuses)
                }
            >
                {({ getFieldValue }) =>
                    getFieldValue('statuses')?.includes(BOOKING_STATUS.Cancelled) ? (
                        <Col span={12} xl={6}>
                            <Form.Item name="cancellationDate" label="Cancellation Date">
                                <RangePicker format={date.format.c} placeholder={['From', 'Till']} />
                            </Form.Item>
                        </Col>
                    ) : null
                }
            </Form.Item>

            <Col span={12} xl={6}>
                <Form.Item noStyle shouldUpdate>
                    {({ setFieldValue }) => (
                        <Form.Item name="suppliers" label="Supplier">
                            <SupplierSelector
                                multiple
                                showHiddenValue
                                onChange={(value) =>
                                    !value?.find((item) => PAYMENT_TYPE_SUPPLIERS.includes(item)) &&
                                    setFieldValue('paymentType', null)
                                }
                            />
                        </Form.Item>
                    )}
                </Form.Item>
            </Col>

            {!isService && (
                <>
                    {!isOfflineBookings && (
                        <Col span={12} xl={6}>
                            <Form.Item name="supplierReferenceCode" label="Supplier Reference Code">
                                <Input placeholder="Supplier Reference Code" />
                            </Form.Item>
                        </Col>
                    )}

                    {!isAgencyOrAgent && (
                        <Col span={12} xl={6}>
                            <Form.Item name="accountManager" label="Account Manager">
                                <AccountManagerSelector placeholder="Account Manager" />
                            </Form.Item>
                        </Col>
                    )}
                </>
            )}

            <Col span={12} xl={6}>
                <Form.Item name="accommodationCountryCode" label="Accommodation Country">
                    <AccommodationCountrySelector
                        placeholder="Country (where the booking was made)"
                        onChange={(_, data) => {
                            bookingSearchForm.setFieldValue('accommodationCountryCode', data);
                            bookingSearchForm.setFieldValue('accommodationLocalityHtId', null);
                        }}
                    />
                </Form.Item>
            </Col>

            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue('accommodationCountryCode') && (
                        <Col span={12} xl={6}>
                            <Form.Item name="accommodationLocalityHtId" label="Accommodation Locality">
                                <AccommodationLocalitySelector
                                    placeholder="Locality"
                                    countryId={getFieldValue('accommodationCountryCode')?.id}
                                    onChange={(_, data) => {
                                        bookingSearchForm.setFieldValue('accommodationLocalityHtId', data);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    )
                }
            </Form.Item>

            {!isService && (
                <>
                    {!isAgencyOrAgent && (
                        <Col span={12} xl={6}>
                            <Form.Item name="marketId" label="Markets">
                                <MarketSelector multiple placeholder="Any Markets" />
                            </Form.Item>
                        </Col>
                    )}
                </>
            )}
            <Col span={12} xl={6}>
                <Form.Item name="adminData" label="Created by">
                    <AdminSelector
                        multiple
                        isOffline
                        onChange={(_, data) => {
                            bookingSearchForm.setFieldValue('adminData', data);
                        }}
                    />
                </Form.Item>
            </Col>

            {!isService && (
                <>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                            JSON.stringify(prevValues.suppliers) !== JSON.stringify(currentValues.suppliers)
                        }
                    >
                        {({ getFieldValue }) => {
                            const suppliers = getFieldValue('suppliers');
                            const showField = suppliers?.find((item) =>
                                PAYMENT_TYPE_SUPPLIERS.includes(item)
                            );

                            if (!showField) return null;

                            return (
                                <Col span={12} xl={6}>
                                    <Form.Item name="paymentType" label="Payment type with hotel">
                                        <PaymentTypeSelector />
                                    </Form.Item>
                                </Col>
                            );
                        }}
                    </Form.Item>
                </>
            )}
            {children}
            <Col span={24}>
                <Space direction="vertical" size="large">
                    <Space size="large">
                        {!isOfflineBookings && !isService ? (
                            <Form.Item noStyle name="isActionRequired" valuePropName="checked">
                                <Checkbox>Action Required Bookings Only</Checkbox>
                            </Form.Item>
                        ) : null}
                        {!isOfflineBookings && !isService ? (
                            <Form.Item noStyle name="isOfflineOnly" valuePropName="checked">
                                <Checkbox>Offline Bookings Only</Checkbox>
                            </Form.Item>
                        ) : null}
                        {!isOfflineBookings && !isService ? (
                            <Form.Item noStyle name="isNoHotelConfirmationCode" valuePropName="checked">
                                <Checkbox>No Hotel Confirmation Code</Checkbox>
                            </Form.Item>
                        ) : null}
                    </Space>
                    <Space size="large">
                        <Button type="primary" htmlType="submit">
                            Search Bookings
                        </Button>
                        <Button
                            htmlType="button"
                            onClick={() => {
                                bookingSearchForm.resetFields();
                                onSubmit({});
                            }}
                        >
                            Clear Filters
                        </Button>
                    </Space>
                </Space>
            </Col>
        </Row>
    </Form>
);

const FILTERS_STORAGE_NAME = 'booking_filters_visible';

const BookingSearchForm = (props) => {
    const onCollapse = (newState) => {
        localStorage.set(FILTERS_STORAGE_NAME, newState === 'filters');
    };

    if (props.isAgencyOrAgent) {
        return (
            <Collapse
                accordion
                defaultActiveKey={[localStorage.get(FILTERS_STORAGE_NAME) === 'false' ? '' : 'filters']}
                style={{ marginBottom: 20 }}
                onChange={onCollapse}
            >
                <Collapse.Panel header="Bookings Filters" key="filters">
                    <BookingSearchFormUnwrapped {...props} />
                </Collapse.Panel>
            </Collapse>
        );
    }

    return <BookingSearchFormUnwrapped {...props} style={{ marginBottom: 30 }} />;
};

export default BookingSearchForm;
