const settings = {
    identityUrl: process.env.IDENTITY_URL,
    identityScope:
        'edo predictions openid email sunpu.read sunpu.edit mapper.edit data-static-manager tsutsujigasaki vcc.accounts akita maruoka',
    identityClientId: 'ueda',
    edo: (culture) => process.env.EDO_URL + culture + '/api/1.0',
    osaka: () => process.env.OSAKA_URL + 'api/1.0',
    komoro: () => process.env.KOMORO_URL + 'api/1.0',
    sunpu: () => process.env.SUNPU_URL + 'api/1.0',
    nakijin: () => process.env.NAKIJIN_URL + 'api/1.0',
    tsutsujigasaki: () => process.env.TSUTSUJIGASAKI_URL + 'api/1.0',
    gifu: () => process.env.GIFU_URL + 'api/1.0',
    shimabara: () => process.env.SHIMABARA_URL + 'api/1.0',
    akita: () => process.env.AKITA_URL,
    maruoka: () => process.env.MARUOKA_URL,
    mapper: () => process.env.MAPPER_URL + 'api/1.0',
    pageTitleSuffix: 'Management Happytravel',
};

export default settings;
