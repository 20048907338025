import React from 'react';
import apiMapperMethods from 'core/mapper-methods';
import { RequestSelector } from 'common/request-selector';

const optionsGenerator = (list = []) =>
    list.map(({ localityName, id }) => ({ label: localityName, value: id }));

const AccommodationLocalitySelector = ({ countryId, ...props }) => (
    <RequestSelector
        request={(value) => ({
            shimabara: apiMapperMethods.accommodationLocality(countryId),
            body: { query: value },
        })}
        optionsGenerator={optionsGenerator}
        placeholder="Any Locality"
        {...props}
    />
);

export default AccommodationLocalitySelector;
