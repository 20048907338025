import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import moment from 'moment/moment';
import apiMethods from 'core/methods';
import { API, localStorage } from 'htcore';
import { date } from 'legacy';
import useCurrencies, { toFixedDownString } from './use-currencies';
import { Form } from 'antd';
import { notifications } from 'components/notification';
import { AccommodationTypes, AccommodationTypesValues } from './offline-booking-page';
import { getTotalPrice } from './rate-details';

const DUPLICATE_LS_KEY = 'duplicated_data';

const createPassengers = ({ leadPassenger, passengers, children }) => {
    const adultPassengers = (passengers || []).map(({ age, firstName, lastName, title }) => ({
        age: age || null,
        firstName: firstName || '',
        isLeader: false,
        lastName: lastName || '',
        title: title || '',
    }));

    const childrenPassengers = (children || []).map(({ age, firstName, lastName, title }) => ({
        age: age || 9,
        firstName: firstName || '',
        isLeader: false,
        lastName: lastName || '',
        title: title || '',
    }));

    return [
        {
            age: null,
            firstName: leadPassenger.firstName,
            isLeader: true,
            lastName: leadPassenger.lastName,
            title: leadPassenger.title,
        },
        ...adultPassengers,
        ...childrenPassengers,
    ];
};

const getPassengersData = (allPassengers) => {
    const leadPassenger = allPassengers?.find((item) => item.isLeader);
    const children = allPassengers.filter((item) => item?.age !== null && item?.age <= 11);
    const passengers = allPassengers.filter((item) => (!!item?.title || item?.age >= 11) && !item.isLeader);

    return {
        leadPassenger,
        passengers,
        children,
        childrenNumber: children.length || 0,
    };
};

const remapExistingBookingToFormData = (booking) => {
    const { checkInDate, checkOutDate, rooms } = booking;

    const rateDetails = rooms?.[0]?.rateDetails;

    return {
        ...booking,
        isUnmapped: AccommodationTypesValues[booking.accommodationType],
        unmappedHtId:
            booking.accommodationType === AccommodationTypes.Unmapped ? { value: booking.htId } : null,
        rangeDates: [moment(checkInDate), moment(checkOutDate)],
        roomsCount: rooms.length,
        supplierCurrency: rateDetails?.supplierPrice?.currency || '',
        customerCurrency: rateDetails?.supplierConvertedPrice?.currency || '',
        rooms: rooms.map((item) => ({
            ...item,
            deadlineDate: moment(item.deadlineDate),
            mealPlan: item.mealPlan ? { label: item.mealPlan, value: item?.boardBasis } : null,
            amount: item?.rateDetails?.supplierPrice?.amount,
            ...getPassengersData(item?.passengers),
        })),
    };
};

export const getRoomsMarkupsAmount = (roomsData, fixedMarkupValue, totalPrice) => {
    const roomAmounts = roomsData.map((room) => new BigNumber(room.amount || room.price));
    const totalCost = totalPrice || getTotalPrice(roomsData);

    // Count proportions to calculate markup for each room
    const markupShares = roomAmounts.map((amount) => {
        const proportion = amount.dividedBy(totalCost);

        const roomMarkup = new BigNumber(fixedMarkupValue).multipliedBy(proportion * 100).dividedBy(100);
        return new BigNumber(toFixedDownString(roomMarkup));
    });

    // Count amount remaining
    const distributedTotal = getTotalPrice(markupShares.map((item) => ({ amount: item })));
    const remainingDifference = new BigNumber(fixedMarkupValue).minus(distributedTotal);

    if (!remainingDifference.isZero()) {
        const lastItemIndex = markupShares.length - 1;

        markupShares[lastItemIndex] = markupShares[lastItemIndex].plus(
            new BigNumber(toFixedDownString(remainingDifference))
        );
    }

    return markupShares;
};

const useOfflineBookingData = ({ form, serviceName, serviceForm }) => {
    const { action, referenceCode } = useParams();
    const [isLoading, setLoading] = useState(false);
    const [booking, setBooking] = useState(null);

    const fixedMarkupAmount = Form.useWatch('fixedMarkupAmount', form);

    const navigate = useNavigate();

    const creation = useMemo(() => action === 'create' || action === 'duplicate', [action]);
    const duplicate = useMemo(() => action === 'duplicate', [action]);

    const { getCurrenciesData, exchangeRate } = useCurrencies({ form, duplicate, booking });

    const getSavedBooking = () => {
        API.get({
            url: apiMethods.offlineBookingsByReferenceCode(referenceCode),
            success: async (result) => {
                serviceForm.setFieldValue('serviceName', result.serviceName);
                setBooking(remapExistingBookingToFormData(result));
            },
        });
    };

    const loadDuplicatedData = () => {
        if (referenceCode) {
            getSavedBooking();
            return;
        }

        const savedData = JSON.parse(localStorage.get(DUPLICATE_LS_KEY));
        serviceForm.setFieldValue('serviceName', savedData.serviceName);

        const [dateFrom, dateTo] = savedData?.rangeDates || [];
        setBooking({
            ...savedData,
            rangeDates: dateFrom && dateTo ? [moment(dateFrom), moment(dateTo)] : null,
        });
    };

    const load = () => {
        if (creation) return;
        getSavedBooking();
    };

    useEffect(() => {
        if (duplicate) {
            loadDuplicatedData();
            return;
        }
        load();
    }, [action, referenceCode]);

    const createRooms = (values) => {
        const roomsMarkupsAmount = getRoomsMarkupsAmount(values.rooms, fixedMarkupAmount);

        return values.rooms.map(
            ({ contractName, mealPlan, adultsNumber, deadlineDate, amount, ...restValues }, index) => {
                const { supplierCurrency, customerCurrency, totalWithoutMarkup, totalInUsd } =
                    getCurrenciesData(amount);

                let customerPriceAmount = toFixedDownString(
                    new BigNumber(totalWithoutMarkup).plus(roomsMarkupsAmount[index].toNumber())
                );

                return {
                    type: 'NotSpecified',
                    boardBasis: mealPlan?.value || null,
                    mealPlan: mealPlan?.label || null,
                    adultsNumber: adultsNumber || 1,
                    passengers: createPassengers(restValues),
                    rateDetails: {
                        supplierPrice: {
                            amount,
                            currency: supplierCurrency,
                        },
                        supplierConvertedPrice: {
                            amount: toFixedDownString(totalWithoutMarkup),
                            currency: customerCurrency,
                        },
                        supplierUsdPrice: totalInUsd,
                        customerPrice: {
                            amount: customerPriceAmount,
                            currency: customerCurrency,
                        },
                    },
                    deadlineDate: date.format.apiDateOnly(deadlineDate),
                    contractName,
                    dailyRateDetails: [],
                };
            }
        );
    };

    const getAgencyForSurrogate = () => {
        const isDev =
            window.location.hostname === 'localhost' ||
            window.location.hostname === 'manage-dev.happytravel.com';

        return isDev ? 890 : 3;
    };

    const remapFormDataToSubmit = (values) => {
        const {
            agencyId,
            agentId,
            supplierCode,
            subSupplier,
            htId,
            unmappedHtId,
            rangeDates,
            nationality,
            residency,
            isNonRefundable,
            description,
            markupValue,
            fixedMarkupAmount,
            surrogateAgencyId,
            selectedItineraryNumber,
            isUnmapped,
        } = values;

        return {
            serviceName,
            agencyId: surrogateAgencyId ? getAgencyForSurrogate() : agencyId,
            agentId,
            supplierCode: supplierCode || 'notContracted',
            subSupplier,
            htId: htId || unmappedHtId.value,
            checkInDate: date.format.apiDateOnly(rangeDates[0]),
            checkOutDate: date.format.apiDateOnly(rangeDates[1]),
            nationality,
            residency,
            description: description || '',
            rooms: createRooms(values),
            isNonRefundable: !!isNonRefundable,
            markupType: 0,
            markupValue,
            fixedMarkupAmount,
            surrogateAgencyId,
            selectedItineraryNumber: selectedItineraryNumber || null,
            accommodationType: isUnmapped ? 1 : 0,
        };
    };

    const redirectToBooking = (refCode) => {
        localStorage.remove(DUPLICATE_LS_KEY);
        navigate(`/booking/${refCode}`);
    };

    const submit = (_, onSuccess) => {
        form.validateFields().then((values) => {
            setLoading(true);
            const body = remapFormDataToSubmit(values);

            if (creation) {
                API.post({
                    url: apiMethods.offlineBookings,
                    body,
                    success: (refCode) => {
                        notifications('Booking Created', 'success');
                        onSuccess ? onSuccess(refCode) : redirectToBooking(refCode);
                    },
                    after: () => setLoading(false),
                });
            } else {
                API.put({
                    url: apiMethods.offlineBooking(referenceCode),
                    body,
                    success: () => {
                        notifications('Booking Saved', 'success');
                        onSuccess ? onSuccess(referenceCode) : redirectToBooking(referenceCode);
                    },
                    after: () => setLoading(false),
                });
            }
        });
    };

    const confirmRequest = (refCode) => {
        setLoading(true);
        API.post({
            url: apiMethods.confirmBooking(refCode || referenceCode),
            success: () => {
                notifications('Booking Confirmed', 'success');
                redirectToBooking(refCode || referenceCode);
            },
            error: () => {
                navigate(`/offline-bookings/booking/edit/${refCode}`);
            },
            after: () => setLoading(false),
        });
    };

    const confirmBooking = () => {
        if (form.isFieldsTouched() || creation) {
            submit(form.getFieldsValue(), confirmRequest);
            return;
        }
        confirmRequest(referenceCode);
    };

    const duplicateBooking = () => {
        localStorage.set(DUPLICATE_LS_KEY, JSON.stringify({ serviceName, ...form.getFieldsValue() }));
    };

    return {
        isLoading,
        creation,
        duplicate,
        booking,
        exchangeRate,
        confirmBooking,
        duplicateBooking,
        submit,
        getCurrenciesData,
    };
};

export default useOfflineBookingData;
