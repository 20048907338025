import React from 'react';
import apiMapperMethods from 'core/mapper-methods';
import { RequestSelector } from 'common/request-selector';

const optionsGenerator = (list) =>
    list.map(({ localityHtId, localityName }) => ({ value: localityHtId, label: localityName }));

const MappingLocalitySelector = ({ countryCode, ...props }) => {
    return (
        <RequestSelector
            request={(value) => ({
                shimabara: apiMapperMethods.searchLocalitiesByCodeAndQuery,
                body: {
                    countryCode,
                    query: value,
                },
            })}
            optionsGenerator={optionsGenerator}
            placeholder="Any Locality"
            {...props}
        />
    );
};

export default MappingLocalitySelector;
