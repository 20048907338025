import React from 'react';
import { Button, Checkbox, Col, Form, Input, Radio, Row, Space, Typography } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import MapperSuppliersSelector from './mapping-suppliers-selector';

const { TextArea } = Input;
const { Title } = Typography;

const checkboxOptions = [
    'SupplierPropertyDownload',
    'PropertyLocalityRelationUpdate',
    'GiataPropertyDownload',
    'StaticDataUpdate',
    'LocalityDataUpdate',
    'StaticDataPublication',
    'Mapping',
];

const RunMappingForm = ({ loading, form }) => {
    const suppliersData = Form.useWatch('suppliers', form);

    return (
        <>
            <Form.List name="suppliers" label="Suppliers">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }, index) => {
                            const isFirst = index === 0;

                            return (
                                <Row gutter={20} key={key}>
                                    <Col span={4}>
                                        <Form.Item
                                            name={[name, 'supplierId']}
                                            label="Supplier"
                                            rules={[{ required: true, message: 'Please select Supplier' }]}
                                            {...restField}
                                        >
                                            <MapperSuppliersSelector
                                                suppliersData={suppliersData}
                                                onChange={(value) => {
                                                    form.setFieldValue(['suppliers', name], {
                                                        accommodationIds: null,
                                                        supplierId: value,
                                                    });
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col span={20}>
                                        <Row>
                                            <Form.Item
                                                name={[name, 'accommodationIds']}
                                                label="Supplier Accommodation IDs"
                                                style={{ marginRight: 15, flex: 1 }}
                                                {...restField}
                                            >
                                                <TextArea placeholder="ID" style={{ minHeight: 120 }} />
                                            </Form.Item>

                                            <Button
                                                style={{ marginTop: 6 }}
                                                onClick={() =>
                                                    isFirst
                                                        ? add({ accommodationIds: null, supplierId: null })
                                                        : remove(name)
                                                }
                                            >
                                                {isFirst ? <PlusOutlined /> : <MinusOutlined />}
                                            </Button>
                                        </Row>
                                    </Col>
                                </Row>
                            );
                        })}
                    </>
                )}
            </Form.List>

            <Title level={4} style={{ margin: '20px 0' }}>
                Workers to be run
            </Title>

            <Form.Item
                name="types"
                style={{ marginBottom: 10 }}
                rules={[{ required: true, message: 'Please select Workers' }]}
            >
                <Checkbox.Group
                    options={checkboxOptions.map((item) => ({ label: item, value: item }))}
                    style={{
                        width: 600,
                        display: 'grid',
                        gridTemplateColumns: 'repeat(2, 1fr)',
                        gridGap: '24px',
                    }}
                />
            </Form.Item>

            <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) =>
                    getFieldValue('types')?.includes('Mapping') && (
                        <Form.Item name="mappingType" style={{ margin: '0 0 0 40px' }}>
                            <Radio.Group defaultValue="Giata">
                                <Space direction="vertical">
                                    <Radio value="Giata">Giata</Radio>
                                    <Radio value="CrossCode">Cross-Code</Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                    )
                }
            </Form.Item>

            <Button loading={loading} htmlType="submit" type="primary" style={{ marginTop: 40, width: 170 }}>
                Run
            </Button>
        </>
    );
};

export default RunMappingForm;
