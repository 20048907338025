import React, { useEffect, useState } from 'react';
import { Typography } from 'antd';
import { API } from 'htcore';
import apiMappingMethods from 'core/mapping-methods';
import { Loader } from 'legacy';
import Table from 'components/table';
import UploadFileForm from './upload-file-form';
import SuppliersActions from './suppliers-actions';

const { Title } = Typography;

const columns = (updateData) => [
    {
        header: 'Supplier',
        cell: ({ supplier }) => supplier,
    },
    {
        header: 'Number of Codes',
        cell: ({ numberOfCodes }) => numberOfCodes,
    },
    {
        header: 'Actions',
        cell: ({ supplier }) => <SuppliersActions supplier={supplier} updateData={updateData} />,
    },
];

const CrossCodes = () => {
    const [list, setList] = useState(null);
    const [loading, setLoading] = useState(true);

    const getData = () => {
        setLoading(true);
        API.get({
            mapper: apiMappingMethods.crossCodeCount,
            success: (result) =>
                setList(
                    Object.entries(result).map(([supplier, numberOfCodes]) => ({
                        supplier,
                        numberOfCodes,
                    }))
                ),
            after: () => setLoading(false),
        });
    };

    useEffect(() => {
        getData();
    }, []);

    if (loading) {
        return <Loader />;
    }

    return (
        <>
            <UploadFileForm updateData={getData} />

            <Title level={4} style={{ marginTop: 40 }}>
                Uploaded Files
            </Title>
            <Table list={list} columns={columns(getData)} />
        </>
    );
};

export default CrossCodes;
