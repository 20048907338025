import React from 'react';
import { EntitySelector, useSelector } from 'common/entity-selector';
import sunpuMethods from 'core/sunpu-methods';

const getFilteredOptions = (list, onlyEnabled, withTestOnly) => {
    if (!onlyEnabled) return list;

    if (withTestOnly)
        return list.filter((item) => item.enableState === 'TestOnly' || item.enableState === 'Enabled');

    return list.filter((item) => item.enableState === 'Enabled');
};

const optionsGenerator = (list, onlyEnabled) =>
    getFilteredOptions(list, onlyEnabled).map((item) => ({
        value: item.code,
        label: item.name,
    }));

const SupplierSelector = ({ onlyEnabled, withTestOnly = true, showHiddenValue = false, ...props }) => {
    const { options, loading } = useSelector(
        { sunpu: sunpuMethods.suppliers, body: { showHidden: showHiddenValue } },
        (list) => optionsGenerator(list, onlyEnabled, withTestOnly)
    );

    return <EntitySelector placeholder="Any Supplier" {...props} options={options} loading={loading} />;
};

export default SupplierSelector;
