const apiMappingMethods = {
    runMapping: '/data-processing-task-groups',
    jobsQueue: (fromDate) => `/data-processing-task-groups?top=100&skip=0&fromDate=${fromDate}`,
    jobsCount: '/data-processing-task-groups/count',

    crossCodeCount: '/properties/suppliers/cross-codes/count',
    crossCodesUploadFile: (supplier) => `/properties/suppliers/${supplier}/cross-codes/upload`,
    crossCodesDownload: (supplier) => `/properties/suppliers/${supplier}/cross-codes/download`,
    crossCodesRemove: (supplier) => `/properties/suppliers/${supplier}/cross-codes/remove`,
};

export default apiMappingMethods;
