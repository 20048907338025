import React, { useEffect, useState } from 'react';
import { API } from 'htcore';
import apiMappingMethods from 'core/mapping-methods';
import { Loader, date } from 'legacy';
import { Space } from 'antd';

import TaskGroup from './task-group';

const getDateTwoDaysAgo = () => {
    const now = new Date();
    const timestamp48HoursAgo = now.getTime() - 48 * 60 * 60 * 1000;
    return new Date(timestamp48HoursAgo);
};

const JobsQueue = () => {
    const [list, setList] = useState(null);

    const getData = () => {
        API.get({
            mapper: apiMappingMethods.jobsQueue(date.format.apiTime(getDateTwoDaysAgo())),
            success: setList,
        });
    };

    useEffect(() => {
        getData();
    }, []);

    if (!list) {
        return <Loader />;
    }

    return (
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
            {list?.map((group, index) => (
                <TaskGroup key={index} index={index} group={group} />
            ))}
        </Space>
    );
};

export default JobsQueue;
