import React, { useMemo } from 'react';
import sunpuMethods from 'core/sunpu-methods';
import { EntitySelector, useSelector } from 'common/entity-selector';

const optionsGenerator = (list) =>
    list
        .filter(({ supplierDetails }) => supplierDetails.contractType === 'OnlineAndOffline')
        .map(({ supplierDetails }) => ({
            value: supplierDetails.supplierCode,
            label: supplierDetails.supplierName,
        }));

const MapperSuppliersSelector = ({ suppliersData, ...props }) => {
    const { options, loading } = useSelector({ sunpu: sunpuMethods.suppliersData }, (list) =>
        optionsGenerator(list)
    );

    const filteredOptions = useMemo(() => {
        if (!suppliersData) return options;

        const selectedSuppliers = suppliersData.map(({ supplierId }) => supplierId).filter((item) => item);

        return options.filter(({ value }) => !selectedSuppliers.includes(value) || value === props.value);
    }, [suppliersData, options]);

    return (
        <EntitySelector placeholder="Any Supplier" {...props} options={filteredOptions} loading={loading} />
    );
};

export default MapperSuppliersSelector;
